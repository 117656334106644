<template>
    <div
        class="relative py-8 lg:py-20"
        :class="{ 'bg-brown-100': client, 'bg-[#E3E9DE]': colleague && !specialist, 'bg-[#F8FFFC]': specialist }"
    >
        <div class="container relative">
            <div
                class="
                    absolute
                    w-40
                    h-40
                    right-4
                    top-1/3
                    bg-contain bg-right-bottom bg-no-repeat
                    lg:w-80 lg:h-80 lg:bottom-auto lg:-top-10 lg:bg-center
                "
                :class="{
                    'lg:right-auto lg:left-1/2 lg:-translate-x-1/2': generic,
                    'lg:right-auto lg:left-44 xl:left-52': colleague,
                }"
            >
                <svg
                    width="190"
                    height="148"
                    viewBox="0 0 190 148"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-full h-full object-cover"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M76.6044 137.429C68.7937 144.476 59.0805 148 47.4647 148C32.6445 148 21.0286 143.268 12.6172 133.804C4.20573 124.34 0 111.755 0 96.049C0 79.9401 2.50341 66.2476 7.51023 54.9714C12.5171 43.6952 18.5252 34.332 25.5348 26.8816C32.5443 19.4313 39.8543 13.5918 47.4647 9.36327C55.075 5.13469 61.684 2.01361 67.2917 0L86.5179 32.6204C78.5069 35.8422 71.6977 40.9769 66.09 48.0245C60.4824 55.0721 57.278 62.6231 56.4769 70.6776C64.4878 70.6776 71.7978 74.2014 78.4068 81.249C85.0158 88.2966 88.3203 97.6599 88.3203 109.339C88.3203 121.018 84.415 130.381 76.6044 137.429ZM178.284 137.429C170.473 144.476 160.76 148 149.144 148C134.324 148 122.708 143.268 114.297 133.804C105.885 124.34 101.68 111.755 101.68 96.049C101.68 79.9401 104.183 66.2476 109.19 54.9714C114.197 43.6952 120.205 34.332 127.214 26.8816C134.224 19.4313 141.534 13.5918 149.144 9.36327C156.755 5.13469 163.364 2.01361 168.971 0L188.198 32.6204C180.187 35.8422 173.377 40.9769 167.77 48.0245C162.162 55.0721 158.958 62.6231 158.157 70.6776C166.168 70.6776 173.477 74.2014 180.087 81.249C186.695 88.2966 190 97.6599 190 109.339C190 121.018 186.095 130.381 178.284 137.429Z"
                        :fill="generic ? '#EAF3F7' : specialist ? '#BEFAE1' : '#D9D5D1'"
                    />
                </svg>
            </div>

            <div id="review-carousel-container" class="grid grid-cols-12 swiper-container relative z-10">
                <div
                    class="col-span-12 col-start-1 lg:col-start-2"
                    :class="{
                        'lg:col-span-6': generic,
                        'lg:col-span-5': colleague,
                        'lg:col-span-3': client,
                    }"
                >
                    <h2 class="header-1 mb-5 lg:mb-10">{{ title }}</h2>

                    <ButtonLink
                        v-if="generic"
                        :label="$t('testimonial.see_other_reviews')"
                        :to="localePath('about-testimonials')"
                        class="hidden lg:inline-block"
                    />
                </div>

                <div
                    class="col-span-12 relative row-span-2"
                    :class="{
                        'lg:col-span-4': generic,
                        'lg:col-span-5': client || colleague,
                        'lg:col-start-6': client,
                        'lg:col-start-8': colleague,
                    }"
                >
                    <div id="review-carousel" class="swiper pb-10 lg:pb-0 lg:mb-5">
                        <div class="swiper-wrapper items-start">
                            <div v-for="review in reviews" :key="`review-${review.id}`" class="swiper-slide flex-col">
                                <h3 class="font-HKGrotesk-Bold text-dark-500 mb-3">{{ review.name }}</h3>
                                <p v-if="generic || client" class="text-dark-500">{{ review.content }}</p>
                                <p v-else class="text-dark-500 quote">"{{ review.content }}"</p>
                                <Rating
                                    v-if="generic || client"
                                    :value="review.rating"
                                    :readonly="true"
                                    :stars="5"
                                    :cancel="false"
                                    class="mt-5"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-show="reviews.length > 1" class="hidden lg:flex justify-end">
                        <div
                            class="swiper-button-prev sm:mr-4"
                            :class="{ dark: client || (colleague && !specialist) }"
                        ></div>
                        <div class="swiper-button-next" :class="{ dark: client || (colleague && !specialist) }"></div>
                    </div>

                    <div v-show="reviews.length > 1" class="lg:hidden swiper-pagination"></div>
                </div>

                <div v-if="generic" class="col-span-12 lg:hidden mt-2">
                    <ButtonLink
                        :label="$t('testimonial.see_other_reviews')"
                        :to="localePath('about-testimonials')"
                        class="xs-w-full"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Rating from 'primevue/rating';

/*
 *
 * testimonial types:
 * "generic" - like the one on index
 * "client" - client testimonials, like the one on service endpage
 * "colleague" - colleague testimonials, like on the job endpage
 * "colleague specialist" - specialist colleague testimonials by ex-participants of trainings, like on the training endpage
 *
 * */

export default {
    name: 'EmployeeBlock',
    components: {
        Rating,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        reviews: {
            type: Array,
            required: true,
        },
        generic: {
            type: Boolean,
            required: false,
            default: false,
        },
        client: {
            type: Boolean,
            required: false,
            default: false,
        },
        colleague: {
            type: Boolean,
            required: false,
            default: false,
        },
        specialist: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.reviewCarousel = new this.$swiper('#review-carousel', {
            slidesPerView: 1,
            spaceBetween: 12,
            resistance: true,
            resistanceRatio: 0,
            allowTouchMove: this.$device.isMobileOrTablet || window.innerWidth < 768,
            speed: 1000,
            navigation: {
                nextEl: '#review-carousel-container .swiper-button-next',
                prevEl: '#review-carousel-container .swiper-button-prev',
            },
            pagination: {
                el: '#review-carousel-container .swiper-pagination',
            },
            autoplay: {
                delay: 7000,
            },
            loop: false,
        });
    },
};
</script>

<style scoped></style>
